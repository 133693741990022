.anusuchi-1 .col-md-4,
.anusuchi-1 .col-md-12 {
  margin-bottom: 25px;
}

.form-container {
  margin-top: 3rem;
}

.anusuchi3-heading td {
  /* -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)'; */
  height: auto;
  width: auto;
}

.pdf-table table,
.pdf-table th,
.pdf-table td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.date-control {
  background-color: transparent !important;
}

.MuiTableCell-body .material-icons {
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.1rem;
  /* background-color: red; */
  /* color: red !important; */
}

.required {
  color: red !important;
}

#abedan-data .MuiTable-root:first-child {
  width: 3000px !important;
}

/* .pradesh-class {
  background-color: #727272 !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */
